<template>
  <div
    id="order-document"
    class="order-document"
    dir="rtl"
    style="clip-path: inset(0 100% 0 0)"
  >
    <section class="top">
      <div class="title-wrapper">
        <h1 class="title">הזמנת רכש מספר {{ order.orderNumber }}</h1>
        <h2 v-if="order && order.account">
          {{
            $t('customer.orderDocumentBuilder.fromCustomer', [
              order.account.companyName,
              order.account.businessId
            ])
          }}
        </h2>
      </div>
      <img class="logo" :src="logoUri" />
    </section>
    <section class="table-wrapper">
      <table style="border: 1px solid black; width: 100%">
        <tr>
          <th>{{ $t('customer.orderDocumentBuilder.orderDetails') }}</th>
          <th>{{ $t('customer.orderDocumentBuilder.vendorDetails') }}</th>
        </tr>
        <tr v-for="(field, index) in orderDetailFields" :key="`field_${index}`">
          <td v-for="innerField in field.fields" :key="innerField.title">
            <span v-if="innerField.title" class="title"
              >{{ innerField.title }}:</span
            >
            <span v-if="innerField.value">{{ innerField.value }}</span>
          </td>
        </tr>
      </table>
    </section>

    <section
      class="table-wrapper"
      v-if="order && order.orderItems && order.orderItems.length"
    >
      <table style="border: 1px solid black">
        <tr>
          <th style="width: 10%">מספר פריט</th>
          <th style="width: 15%">שם הפריט</th>
          <th style="width: 15%">תאריך אספקה</th>
          <th style="width: 10%">יחידת מידה</th>
          <th style="width: 10%">כמות</th>
          <th style="width: 10%">מחיר יחידה</th>
          <th style="width: 10%">סה״כ</th>
        </tr>
        <tr v-for="(item, index) in order.orderItems" :key="`item_${index}`">
          <td>{{ item.catalogItem.itemCode }}</td>
          <td>{{ item.catalogItem.itemName }}</td>
          <td>{{ format(new Date(item.supplyDate), 'dd/MM/yyyy') }}</td>
          <td>{{ item.catalogItem.itemUnitOfMeasureText }}</td>
          <td>{{ item.quantity }}</td>
          <td>
            <span v-if="order.currency"
              >{{
                item.pricePerUnit.toLocaleString(undefined, {
                  style: 'currency',
                  currency: order.currency || ''
                })
              }}
            </span>
          </td>
          <td>
            <span v-if="order.currency">
              {{
                (item.pricePerUnit * item.quantity).toLocaleString(undefined, {
                  style: 'currency',
                  currency: order.currency || ''
                })
              }}</span
            >
          </td>
        </tr>
      </table>
    </section>
    <section class="summary">
      <div class="table-wrapper" style="flex-grow: 1">
        <table style="border: 1px solid black; width: 100%">
          <tr>
            <th>תיאור</th>
          </tr>
          <tr>
            <td style="word-wrap: break-word; white-space: pre-wrap">
              {{ order.description }}
            </td>
          </tr>
        </table>
      </div>
      <div class="summary-items">
        <div class="item" v-for="item in summaryItems" :key="item.title">
          <div>{{ item.title }}</div>
          <div class="value">
            {{ item.value }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import image from '@/assets/corz.png';
import { format } from 'date-fns';
export default {
  name: 'OrderDocumentBuilder',
  props: {
    order: {
      type: Object,
      required: true
    },
    selectedVendor: {
      type: Object
    },
    logoUri: {
      type: String
    }
  },

  created() {},
  data() {
    return {
      image,
      items: [],
      orderDetailFields: [
        {
          fields: [
            {
              title: 'תאריך הזמנה',
              value: this.order?.issueDate
                ? format(new Date(this.order.issueDate), 'dd/MM/yyyy')
                : ''
            },
            {
              title: 'שם',
              value:
                this.selectedVendor?.businessName ||
                this.order?.vendor?.businessName
            }
          ]
        },
        {
          fields: [
            {
              title: 'מספר הזמנה',
              value: this.order?.orderNumber
            },
            {
              title: 'ח.פ.',
              value: this.selectedVendor?._id || this.order?.vendor?._id
            }
          ]
        },
        {
          fields: [
            {
              title: 'כותרת הזמנה',
              value: this.order?.title
            },
            {
              title: 'טלפון',
              value:
                this.selectedVendor?.contactPhoneNumber ||
                this.order?.vendor?.contactPhoneNumber
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: 'כתובת מייל',
              value:
                this.selectedVendor?.contactEmail ||
                this.order?.vendor?.contactEmail
            }
          ]
        },
        {
          fields: [
            {
              title: '',
              value: ''
            },
            {
              title: 'כתובת',
              value: this.selectedVendor?.address || this.order?.vendor?.address
            }
          ]
        }
      ],
      summaryItems: [
        {
          title: 'סה״כ',
          value:
            this.order?.currency && this.order?.netAmount
              ? this.order.netAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        },
        {
          title: 'מע״מ',
          value:
            this.order?.currency && this.order?.vatAmount !== undefined
              ? this.order.vatAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        },
        {
          title: 'סה״כ כולל מע״מ',
          value:
            this.order?.currency && this.order?.totalAmount
              ? this.order.totalAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: this.order.currency
                })
              : ''
        }
      ]
    };
  },
  methods: {
    format
  }
};
</script>

<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
body {
  margin: 0;
}

.order-document {
  padding: 10px 50px;
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

.top .title {
  font-weight: 600;
}

.logo {
  border-radius: 50%;
  border: 1px solid #fed1f4;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.fields {
  display: flex;
  align-items: center;
  padding: 30px;
  column-gap: 20px;
  row-gap: 5px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.field-wrapper {
  display: flex;
  align-items: center;
}

.field {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.field-input {
  background-color: #fed1f4;
  padding: 0;
  font-size: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.field-name {
  width: 150px;
}

.field-value {
  width: 200px;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-wrapper h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.table-wrapper table tr th {
  background-color: #fed1f4;
  padding: 10px;
  border: 1px solid black;
  font-weight: 600;
  text-align: start;
  font-size: 18px;
}

.table-wrapper table tr td {
  padding: 10px;
  border: 1px solid black;
}

.table-wrapper table tr td .title {
  font-weight: 500;
  margin-left: 10px;
}

table {
  border-collapse: collapse;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.summary-items {
  display: flex;
  flex-direction: column;
}

.summary-items .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.summary-items .item .title {
  font-weight: 500;
}

.summary-items .item .value {
  border: 1px solid black;
  padding: 5px;
  min-width: 120px;
}
</style>
